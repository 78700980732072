/** @jsx jsx */
import { Flex, Box, jsx, Heading, Text } from 'theme-ui'
import { Slider, Badge } from 'antd'
import { useState } from 'react'
import numeral from 'numeral'
import { LinkButton } from '../../../components'
import { ListItemWithIcon, ListGroup } from './list-items'
import PlanCard from './plan-card'
import { PRICE_FORMAT } from '../../../common/display-formats'

const PRICE_PER_CONTRIBUTOR_PER_MONTH = 19

const BusinessPlan = ({ isCurrentPlan }) => {
  const [numOfContributors, setNumOfContributors] = useState(125)
  const planCard = (
    <PlanCard>
      <Box>
        <Heading as="h3" variant="cardTitle">
          Business
        </Heading>
        <Text variant="summary">For most of the teams</Text>
      </Box>
      <Box>
        <Text variant="title">
          {numeral(numOfContributors * PRICE_PER_CONTRIBUTOR_PER_MONTH).format(
            PRICE_FORMAT
          )}
        </Text>
        <Text>
          {numeral(PRICE_PER_CONTRIBUTOR_PER_MONTH).format(PRICE_FORMAT)} per
          developer/month
        </Text>
        <Text variant="summary">Per month billed annually</Text>
      </Box>
      <Box>
        <LinkButton
          sx={{ pointerEvents: isCurrentPlan ? 'none' : 'auto' }}
          text={isCurrentPlan ? 'Current Plan' : 'Contact Sales'}
          href="mailto:sales@spectralops.io?subject=Upgrade%20to%20Business%20plan"
          block
          size="large"
        />
        <Flex sx={{ flexDirection: 'column', mt: 3 }}>
          <Slider
            min={51}
            max={200}
            onChange={(e) => {
              setNumOfContributors(e)
            }}
            value={numOfContributors}
            tooltipVisible={false}
            autoFocus
            style={{ marginTop: '2px', marginBottom: '3px' }}
          />
          <Text sx={{ m: 'auto' }}>{numOfContributors} Contributors</Text>
        </Flex>
      </Box>
      <Box>
        <Text sx={{ mb: 3 }}>All the benefits of Free, plus:</Text>
      </Box>
      <Box>
        <ListGroup>
          <ListItemWithIcon text="Unlimited scans" />
          <ListItemWithIcon text="Unlimited repositories" />
          <ListItemWithIcon text="Up to 200 contributors" />
          <ListItemWithIcon text="Up to 100 repositories" />
          <ListItemWithIcon text="Up to 50 contributors" />
          <ListItemWithIcon text="Chat and email support" />
          <ListItemWithIcon text="Six months of data retention" />
          <ListItemWithIcon text="Mail, Slack, MS Teams, PagerDuty and webhook notifications" />
          <ListItemWithIcon text="Asset Mapping" />
          <ListItemWithIcon text="Reports & Insights" />
        </ListGroup>
      </Box>
    </PlanCard>
  )
  if (isCurrentPlan) {
    return (
      <Badge.Ribbon text="Your Plan" color="cyan">
        {planCard}
      </Badge.Ribbon>
    )
  }
  return planCard
}

export default BusinessPlan
