/** @jsx jsx */
import { useState } from 'react'
import {
  timeSinceInDays,
  AssetType,
  toLongDisplayNumber,
} from '@spectral/types'
import { ClockCircleOutlined } from '@ant-design/icons'
import { List, Tooltip, Collapse } from 'antd'
import map from 'lodash/map'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { Box, Flex, jsx, Text, Link } from 'theme-ui'
import { BaseSeverityTag } from '../../../../components'
import { PassedPeriod } from '../../../../components/formatters'
import { Loading } from '../../../../components/loading'
import AssetIcon from '../../../../components/asset-icon/AssetIcon'
import IssuePath from '../../../../components/asset-page/issue-filepath/issue-filepath'
import SecretSprawlCollapse from '../../../../components/collapse/styled-collapse'
import IgnorePopover from '../../../ignore-popover/view'
import DetectorInfo from '../../../detector-name/view'
import { itemsToDisplayInPage } from '../../shared/list-page-setup'
import track from '../../../../common/track/asset-page'
import EmptyAsset from '../../empty-asset'
import IssueCollapse from '../../issue-collapse/issue-collapse'
import Pagination from '../../../../components/pagination'
import IssueLinks from '../../issue-links'

const SecretSprawlItem = ({ issue }) => {
  const {
    link,
    detectorName,
    detectorId,
    detectorDescription,
    content,
    asset: { displayName, url: assetUrl, type: assetType, source: assetSource },
    uri,
    firstSeen,
    displaySeverity,
    ignore: {
      isIgnored,
      ignoreType,
      comment,
      ignoreDate,
      actor,
      timeSnoozeOver,
    },
    orgTeam,
  } = issue

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex sx={{ mb: 2 }}>
          <Box mx={3}>
            <Box mx={2} />
            {isIgnored && (
              <IgnorePopover
                ignoreType={ignoreType}
                ignoreDate={ignoreDate}
                comment={comment}
                actor={actor}
                timeSnoozeOver={timeSnoozeOver}
              />
            )}
          </Box>
          <Box mr={2}>
            <Flex mb={2}>
              <Box mr={2}>
                <BaseSeverityTag kind={displaySeverity} emphasized />
              </Box>
              <DetectorInfo
                isIgnored={isIgnored}
                link={link}
                detectorId={detectorId}
                detectorName={detectorName}
                onClick={() => track.playbookClicked(detectorId)}
              />
            </Flex>
            <Flex>
              <Box sx={{ mr: '2px' }}>
                <AssetIcon kind={assetType} source={assetSource} />
              </Box>
              {assetType === AssetType.Git ? (
                <Link
                  onClick={track.secretSprawlAssetIdClicked}
                  href={assetUrl}
                  target="_blank"
                >
                  {displayName}
                </Link>
              ) : (
                <Text>{displayName}</Text>
              )}
            </Flex>

            <Flex mt={2}>
              {![AssetType.Slack].includes(assetType) && (
                <IssuePath issue={issue} />
              )}
              {issue.asset.type === AssetType.Slack && (
                <a href={uri} rel="noopener noreferrer" target="_blank">
                  see message
                </a>
              )}
              &nbsp;
              <Text sx={{ color: 'gray.500', mr: 1 }}>({content})</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex sx={{ alignItems: 'center' }}>
          <Box mr={5}>
            <Text>{orgTeam}</Text>
          </Box>
          <Tooltip title="Secret has been exposed for this long">
            <Flex sx={{ alignItems: 'center', mr: 5, minWidth: '91px' }}>
              <Box mr={1}>
                <ClockCircleOutlined />
              </Box>
              <PassedPeriod createdAt={firstSeen} />
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
      {![AssetType.Slack].includes(assetType) && (
        <Box sx={{ pr: '20px', pl: '48px' }}>
          <IssueLinks blameUrl={issue.blameUrl} uri={issue.uri} />
        </Box>
      )}
      <Box sx={{ pr: '20px', pl: '48px', pt: '10px' }}>
        <IssueCollapse
          detectorDescription={detectorDescription}
          issue={issue}
        />
      </Box>
    </Box>
  )
}
const DEFAULT_PAGE = 1
const IN_SECRET_ISSUES_PAGE_SIZE = 5

const SprawlList = styled(List)`
  .ant-list-items {
    width: 100%;
  }
`

const SecretSprawlPanelContent = ({ issues }) => {
  const [inSecretIssuesPage, setInSecretIssuesPage] = useState(DEFAULT_PAGE)
  return issues ? (
    <Box>
      <SprawlList
        dataSource={itemsToDisplayInPage(
          issues,
          inSecretIssuesPage,
          IN_SECRET_ISSUES_PAGE_SIZE
        )}
        renderItem={(item: any) => (
          <List.Item key={item.pid}>
            <Box sx={{ width: '100%' }}>
              <SecretSprawlItem issue={item} />
            </Box>
          </List.Item>
        )}
      />
      <Box sx={{ mt: 3, textAlign: 'right' }}>
        <Pagination
          current={inSecretIssuesPage}
          onChange={(page) => setInSecretIssuesPage(page)}
          total={size(issues)}
          pageSize={IN_SECRET_ISSUES_PAGE_SIZE}
          showSizeChanger={false}
          size="small"
        />
      </Box>
    </Box>
  ) : (
    <Loading />
  )
}

const SECRET_PAGE_SIZE = 10

const SecretSprawlList = ({
  secrets,
  isRefreshing,
  expandedSecret,
  onChangeSecretExpansion,
  onPageChanged,
  currentPage,
  isNotActiveAsset,
}) => {
  const secretsToDisplay: any = itemsToDisplayInPage(
    secrets,
    currentPage,
    SECRET_PAGE_SIZE
  )
  // eslint-disable-next-line no-nested-ternary
  return isRefreshing ? (
    <Loading />
  ) : isEmpty(secrets) ? (
    <Box sx={{ height: '100%', mt: 3 }}>
      <EmptyAsset isNotActive={isNotActiveAsset} />
    </Box>
  ) : (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      <SecretSprawlCollapse
        bordered={false}
        onChange={(updatedlyExpandedSecret) => {
          track.secretSprawlSecretClicked()
          onChangeSecretExpansion(
            updatedlyExpandedSecret ? [updatedlyExpandedSecret] : null
          )
        }}
        activeKey={expandedSecret}
        accordion
      >
        {map(secretsToDisplay, (sprawl) => {
          const firstSeenFromTodayDays = timeSinceInDays(
            sprawl.issues[0].firstSeen
          )
          return (
            <Collapse.Panel
              header={
                <Box id={secretsToDisplay.fingerprint}>
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text sx={{ fontSize: '16px' }}>{sprawl.detectorName}</Text>
                    <Flex>
                      <Text sx={{ fontSize: '12px', mr: 3 }}>
                        {toLongDisplayNumber(sprawl.numberOfAssets)} assets
                      </Text>
                      <Text sx={{ fontSize: '12px', mr: 3 }}>
                        {toLongDisplayNumber(sprawl.issues.length)} issues
                      </Text>
                      <Tooltip
                        title={`The first issue was discoverd ${firstSeenFromTodayDays} ago`}
                      >
                        <Text sx={{ fontSize: '12px' }}>
                          <PassedPeriod createdAt={sprawl.firstSeen} />
                        </Text>
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Box>
              }
              key={sprawl.fingerprint}
            >
              <SecretSprawlPanelContent issues={sprawl.issues} />
            </Collapse.Panel>
          )
        })}
      </SecretSprawlCollapse>

      <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
        <Pagination
          current={currentPage ? parseInt(currentPage, 10) : DEFAULT_PAGE}
          onChange={(page) => onPageChanged(page)}
          total={size(secrets)}
          pageSize={SECRET_PAGE_SIZE}
          showSizeChanger={false}
          disabled={isRefreshing}
        />
      </Flex>
    </Flex>
  )
}

export default SecretSprawlList
