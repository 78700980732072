import { removeFilePathSlash } from '@spectral/types'
import React from 'react'
import { Box, Text } from 'theme-ui'
import { Tooltip } from 'antd'
import theme from '../../../common/theme'

const IssuePath = ({ issue, maxWidth = 700 }) => {
  const {
    filePath,
    metadata,
    ignore: { isIgnored },
  } = issue
  const virtualPath = metadata?.virtual_path
  const filePathPreset = removeFilePathSlash(filePath)
  const pathText = metadata?.issueKey || metadata?.pageTitle || filePathPreset

  let tooltipText = `Issue found in: ${filePathPreset}`

  if (virtualPath) tooltipText += ` » ${virtualPath}`

  return (
    <Box sx={{ maxWidth, mr: 2 }}>
      <Tooltip title={tooltipText}>
        <Box
          sx={{
            color: isIgnored
              ? theme.stylebook.colors.gray['500']
              : theme.stylebook.colors.text,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Text variant="ellipsis">{pathText}</Text>
        </Box>

        {virtualPath && (
          <Box sx={{ pl: 12 }}>
            <Text variant="ellipsis">└ {virtualPath}</Text>
          </Box>
        )}
      </Tooltip>
    </Box>
  )
}

export default IssuePath
