import { FileSearchOutlined } from '@ant-design/icons'
import { buildHostIssuePath } from '@spectral/types/issue'
import { Tooltip } from 'antd'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import theme from '../../../common/theme'

const HostIssuePath = ({ issue, maxWidth = 650 }) => {
  const {
    metadata,
    ignore: { isIgnored },
  } = issue

  const pathText: string = buildHostIssuePath({ issue })

  const tooltipText = metadata?.stage
    ? 'View run'
    : `Issue found in: ${pathText}`

  return (
    <Box sx={{ maxWidth, mr: 2 }}>
      <Tooltip title={tooltipText}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <FileSearchOutlined style={{ marginRight: '2px' }} />
          <Text
            sx={{
              color: isIgnored
                ? theme.stylebook.colors.gray['500']
                : theme.stylebook.colors.text,
            }}
            variant="ellipsis"
          >
            {pathText}
          </Text>
        </Flex>
      </Tooltip>
    </Box>
  )
}

export default HostIssuePath
