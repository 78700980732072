import { CodeOutlined, FileSearchOutlined } from '@ant-design/icons'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import assetPageTracker from '../../common/track/asset-page'
import theme from '../../common/theme'

const IssueLinks = ({ blameUrl, uri }) => {
  return (
    <Flex sx={{ fontSize: theme.stylebook.text.small.fontSize }}>
      {uri && (
        <Box sx={{ mr: '25px' }}>
          <a
            href={uri}
            rel="noopener noreferrer"
            target="_blank"
            onClick={assetPageTracker.viewIssueClicked}
          >
            <CodeOutlined /> View
          </a>
        </Box>
      )}
      {blameUrl && (
        <Box>
          <a
            onClick={assetPageTracker.investigateClicked}
            href={blameUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FileSearchOutlined /> Investigate
          </a>
        </Box>
      )}
    </Flex>
  )
}

export default IssueLinks
