import { client } from './routes'
import { throwIfErrorsOrInvalid } from './validation'

class SpectralBackendSDK {
  badAuthHandler

  constructor(_badAuthHandler) {
    this.badAuthHandler = (response) => {
      if (response.status === 401) _badAuthHandler()
    }
  }

  auth() {
    return client({
      bearer: null,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: ({ parsedResponse }) =>
        throwIfErrorsOrInvalid(parsedResponse),
    }).auth
  }

  scanInsights(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).scanInsights
  }

  assets(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).assets
  }

  customRules(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).customRules
  }

  issues(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).issues
  }

  teams(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).teams
  }

  invites(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).invites
  }

  users(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).users
  }

  integrations(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: (res) => {
        this.badAuthHandler(res)
        throwIfErrorsOrInvalid(res)
      },
    }).integrations
  }

  reports(token?: string) {
    return client({
      bearer: token,
      throwIfError: throwIfErrorsOrInvalid,
      throwIfBadStatus: this.badAuthHandler,
    }).reports
  }
}

export * from './types'
export { SpectralBackendSDK }
