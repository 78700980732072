import track from '../track'

class AssetPage {
  assetTitleClicked() {
    track.event('ASSET_TITLE_CLICKED')
  }

  pagination({ page }) {
    track.event('SINGLE_ASSET_PAGE_PAGINATION', { page })
  }

  filter(filterType, values) {
    track.event('SINGLE_ASSET_PAGE_FILTERED', {
      filterType,
      values,
    })
  }

  playbookClicked(detectorId) {
    track.event('PLAYBOOK_CLICKED', {
      detectorId,
    })
  }

  viewIssueClicked() {
    track.event('VIEW_ISSUE_CLICKED')
  }

  investigateClicked() {
    track.event('INVESTIGATE_CLICKED')
  }

  investigateDiscovertIssueClicked() {
    track.event('INVESTIGATE_DISCOVER_ISSUE_CLICKED')
  }

  sort(sortBy, sortDirection) {
    track.event('SINGLE_ASSET_PAGE_SORTED', {
      sortBy,
      sortDirection,
    })
  }

  scanHistory() {
    track.event('SINGLE_ASSET_PAGE_SCAN_HISTORY')
  }

  export(output, iac: boolean) {
    track.event('ASSET_PAGE_EXPORT_ISSUES', {
      iac,
      output,
    })
  }

  iacFileClicked() {
    track.event('IAC_FILE_CLICKED')
  }

  openSourceFileClicked() {
    track.event('OPEN_SOURCE_FILE_CLICKED')
  }

  secretSprawlSecretClicked() {
    track.event('SECRET_SPRAWL_SECRET_CLICKED')
  }

  secretSprawlAssetIdClicked() {
    track.event('SECRET_SPRAWL_ASSET_ID_CLICKED')
  }

  secretSprawlFilePathClicked() {
    track.event('SECRET_SPRAWL_FILE_PATH_CLICKED')
  }

  scanHistoryItemClicked() {
    track.event('SCAN_HISTORY_ITEM_CLICKED')
  }
}

export default new AssetPage()
