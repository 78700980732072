/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import { Page, PageTitle } from '../../components/page'
import { select } from '../../redux/store'
import Plans from '../../blocks/settings/plans'

export default () => {
  const isLoading = useSelector(select.Plan.isLoading)
  const currentPlanType = useSelector(select.Plan.currentPlanType)

  return (
    <Page name="PLANS" title="Plans & Pricing" isLoading={isLoading}>
      <Flex sx={{ padding: '40px', gap: '40px', flexDirection: 'column' }}>
        <PageTitle>Plans & Pricing</PageTitle>
        <Plans currentPlanType={currentPlanType} />
      </Flex>
    </Page>
  )
}
