import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
import { PlanType, RoleType } from '@spectral/types'
import { Page } from '../../../components/page'
import SSOSetup from '../../../blocks/settings/sso-setup/sso-setup'
import { select } from '../../../redux/store'
import ErrorMessages from '../../../common/errorMessages'
import { paths } from '../../../routes'
import Restricted from '../../role-based-render/restricted'
import RestrictedArea from '../../restricted-area/restricted-area'

const SSOSetupPage = () => {
  const dispatch = useDispatch()
  const currentTeam = useSelector(select.Teams.current)
  const isTeamLoaded = useSelector(select.Teams.isTeamLoaded)
  const settingsPage = useSelector(select.Auth.settingsPage)

  const onSubmit = async (update) => {
    const response = await dispatch.Teams.updateAuthSettings({ ...update })
    if (response.reason) {
      notification.error({
        message: ErrorMessages[response.reason],
      })
      return false
    }

    await dispatch.Teams.fetchCurrentTeam()
    notification.success({
      message: 'Saved successfully',
    })

    return true
  }

  const loginURL = `${window.location.origin}${paths.open.signinSSO(
    currentTeam.pid
  )}`
  const ssoURL = `${window.location.origin}/api/sso/${currentTeam.pid}/acs`
  const audienceURL = `${window.location.origin}/api/sso/${currentTeam.pid}`

  return (
    <Page name="SETTINGS_SSO_SETUP" title="SSO Setup" isLoading={!isTeamLoaded}>
      <RestrictedArea
        minimumPlan={PlanType.Business}
        featureDisplayName="SSO Setup"
      >
        <Restricted roles={[RoleType.Owner]}>
          <SSOSetup
            authSettings={currentTeam.authSettings}
            onSubmit={onSubmit}
            loginURL={loginURL}
            ssoURL={ssoURL}
            audienceURL={audienceURL}
            isUserEmailVerified={settingsPage.isEmailVerified}
            userEmailDomain={settingsPage.userEmailDomain}
            currentTeamPid={currentTeam.pid}
          />
        </Restricted>
      </RestrictedArea>
    </Page>
  )
}

export default SSOSetupPage
