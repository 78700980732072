import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { PlanType } from '@spectral/types'
import { Page } from '../../components/page'
import { OrgTeams } from '../../blocks/settings/org-teams/org-teams'
import { select } from '../../redux/store'
import RestrictedArea from '../restricted-area/restricted-area'

export default () => {
  const dispatch = useDispatch()
  const {
    isSlackActive,
    isMsTeamsActive,
    isJiraActive,
    isPagerDutyActive,
    isMondayEnabled,
    orgTeams,
    isLoading,
  } = useSelector(select.Teams.orgTeamsSettingsPage)
  const hasBusinessPlanOrUp = useSelector(select.Plan.hasBusinessPlanOrUp)

  useEffect(() => {
    if (hasBusinessPlanOrUp) {
      dispatch.Teams.fetchSettings()
      dispatch.Teams.fetchOrgTeams()
    }
  }, [dispatch.Teams, hasBusinessPlanOrUp])

  useEffect(() => {
    if (isJiraActive && hasBusinessPlanOrUp) {
      dispatch.Jira.fetchProjects()
    }
    return () => {
      dispatch.Jira.reset()
    }
  }, [dispatch.Jira, isJiraActive, hasBusinessPlanOrUp])

  useEffect(() => {
    if (isMondayEnabled && hasBusinessPlanOrUp) {
      dispatch.Monday.fetchWorkspaces()
    }
    return () => {
      dispatch.Monday.reset()
    }
  }, [dispatch.Monday, isMondayEnabled, hasBusinessPlanOrUp])

  return (
    <Page
      name="SETTINGS_ORG_TEAMS"
      title="Organization teams"
      isLoading={isLoading}
    >
      <RestrictedArea
        minimumPlan={PlanType.Business}
        featureDisplayName="Asset Mapping and Teams"
      >
        <Box sx={{ padding: '40px' }}>
          <OrgTeams
            teams={orgTeams}
            activeIntegrations={{
              jira: isJiraActive,
              slack: isSlackActive,
              msTeams: isMsTeamsActive,
              pd: isPagerDutyActive,
              monday: isMondayEnabled,
            }}
            onSave={dispatch.Teams.updateOrgTeams}
            onDelete={dispatch.Teams.deleteOrgTeams}
          />
        </Box>
      </RestrictedArea>
    </Page>
  )
}
